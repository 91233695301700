import './Hero.css'
import React from 'react'

const Hero = () => {
  return (
    <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column mt-5">
      <div className="px-4 py-5">
        <div className="py-5 text-center">
          <h1 className="display-5 fw-bold text-white">Silly Side Quests</h1>
          <div className="col-lg-6 mx-auto">
            <p className="fs-5 mb-4">I'm working on making some of my own side quest puzzles. Nothing here is on mainnet yet, but I'd love for you to take a look at the clues, and let me know what you think.</p>
            <p className="fs-5 mb-4">I haven't really done any "puzzle-crafting" before, so I'm willing to bet these are kinda bad... Check 'em out!</p>
          </div>
          <div className="list-group py-4 col-lg-6 mx-auto text-start">
            <a href="/sq01" className="list-group-item list-group-item-action d-flex gap-3 py-3">
              <div className="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 className="mb-0">Silly Side Quest 01 <small className="mb-0 opacity-75">Locate the <code>hash(x)</code> signer</small></h6>
                </div>
                <i className="fa-solid fa-chevron-right"></i>
              </div>
            </a>
            <a href="/sq02" className="list-group-item list-group-item-action d-flex gap-3 py-3">
              <div className="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 className="mb-0">Silly Side Quest 02 <small className="mb-0 opacity-75">Go swimming in a liquidity pool</small></h6>
                </div>
                <i className="fa-solid fa-chevron-right"></i>
              </div>
            </a>
            <a href="/sq03" className="list-group-item list-group-item-action d-flex gap-3 py-3">
              <div className="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 className="mb-0">Silly Side Quest 03 <small className="mb-0 opacity-75">Claim it quick</small></h6>
                </div>
                <i className="fa-solid fa-chevron-right"></i>
              </div>
            </a>
            <a href="/sq04" className="list-group-item list-group-item-action d-flex gap-3 py-3">
              <div className="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 className="mb-0">Silly Side Quest 04 <small className="mb-0 opacity-75">Create an atomicity bomb</small></h6>
                </div>
                <i className="fa-solid fa-chevron-right"></i>
              </div>
            </a>
            <a href="/sq05" className="list-group-item list-group-item-action d-flex gap-3 py-3">
              <div className="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 className="mb-0">Silly Side Quest 05 <small className="mb-0 opacity-75">Bump, bump, bump it up</small></h6>
                </div>
                <i className="fa-solid fa-chevron-right"></i>
              </div>
            </a>
            <a href="/sq06" className="list-group-item list-group-item-action d-flex gap-3 py-3">
              <div className="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 className="mb-0">Silly Side Quest 06 <small className="mb-0 opacity-75">Mux that</small></h6>
                </div>
                <i className="fa-solid fa-chevron-right"></i>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
