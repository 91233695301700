import { useState } from 'react'

const SQ03 = () => {
  let [ clue, setClue ] = useState('Clue will be displayed here.')
  let [ verified, setVerified ] = useState({success: false, message: ''})

  const fetchClue = async () => {
    let clueButton = document.getElementById('clueButton')
    clueButton.disabled = true
    clueButton.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>'
    clueButton.innerHTML += '&nbsp;Generating...'
    const url = 'https://api.elliotfriend.com/sq/sq03'
    let data = await fetch(url)
    let json = await data.json()
    // console.log(json)
    await setClue(json.clue)
    clueButton.innerHTML = 'Generate Another Clue'
    clueButton.disabled = false
    document.getElementById('verifyButton').disabled = false
  }

  const verifyQuest = async () => {
    let verifyButton = document.getElementById('verifyButton')
    verifyButton.disabled = true
    verifyButton.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>'
    verifyButton.innerHTML += '&nbsp;Verifying...'
    const url = 'https://api.elliotfriend.com/sq/sq03'
    let data = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ sponsor: clue.sponsor.public, claimant: clue.claimant.public })
    })
    let json = await data.json()
    // console.log(json)
    await setVerified(json)
    verifyButton.innerHTML = 'Verify'
    verifyButton.disabled = false
  }

  return (
    <main className="container mt-5 text-start">
      <h2>Silly Side Quest 03</h2>
      <h2><small>Claim it quick</small></h2>
      <h3 className="mt-5">Goals and Details</h3>
      <div className="bg-dark">
        <p className="p-3 mb-0">
          We all love Stellar's claimable balances! They're such an incredible way to to send any asset you want, regardless of whether or not the recipient has a trust line setup. Or, if the account really exists on the blockchain yet!
        </p>
        <p className="p-3 mb-0">
          Did you know, however, that you can create a single transaction that both creates <em>and</em> claims the claimable balance!? Yep. I tried it out. You can!!
        </p>
        <p className="p-3 mb-0">
          And, I can hear you asking already, "What's the practical purpose of this?" I don't truly know. It seems overly mathy and complicated to figure out when all you would really need is a simple payment operation. But, it was something cool/interesting I discovered, so here we are...
        </p>
        <p className="p-3 mb-0">
          So, that's your task for this one. Submit a single transaction that creates <strong>and</strong> claims a claimable balance on the testnet. You might need to get into the weeds of XDR, but I believe in you!
        </p>
        <p className="p-3 mb-0">
          <em>Please Note:</em> This is all taking place on the testnet for now. Please don't lose real XLM!
        </p>
      </div>
      <button className="mt-5 btn btn-primary" id="clueButton" onClick={fetchClue}>
        Generate Clue
      </button>
      <button className="mt-5 ms-3 btn btn-primary" id="verifyButton" onClick={verifyQuest}>
        Verify
      </button>
      {
        verified.message
        ? <div className={verified.success ? "mt-3 alert alert-success" : "mt-3 alert alert-danger"}>
            {verified.message}
          </div>
        : null
      }
      {
        typeof(clue) === 'object'
        ? <div className="mt-3 mb-5 bg-dark">
            <p className="p-3 mb-0">
              <strong>Sponsor Account</strong> (the account that needs to <em>create</em> the claimable balance)
            </p>
            <p className="p-3 mb-0">
              <strong>Public Key:</strong> {clue.sponsor.public}<br />
              <strong>Secret Key:</strong> {clue.sponsor.secret}
            </p>
            <p className="p-3 mb-0">
              <strong>Claimant Account</strong> (the account that needs to <em>claim</em> the claimable balance)
            </p>
            <p className="p-3 mb-0">
              <strong>Public Key:</strong> {clue.claimant.public}<br />
              <strong>Secret Key:</strong> {clue.claimant.secret}
            </p>
            <p className="p-3">
              <em>Please Note:</em> For your convenience, both of these accounts have already been funded by FriendBot.
            </p>
          </div>
        : <p className="mt-3 mb-5 bg-dark p-3">
            {clue}
          </p>
      }
    </main>
  )
}

export default SQ03
