import './App.css';
import { Outlet } from 'react-router-dom'
// import Hero from '../Hero/Hero'
import Header from '../Header/Header'

const App = () => {
  return (
    <main className="App">
      <Header />
      <Outlet />
    </main>
  );
}

export default App;
