import { useState } from 'react'

const SQ06 = () => {
  let [ clue, setClue ] = useState('Clue will be displayed here.')
  let [ verified, setVerified ] = useState({success: false, message: ''})

  const fetchClue = async () => {
    let clueButton = document.getElementById('clueButton')
    clueButton.disabled = true
    clueButton.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>'
    clueButton.innerHTML += '&nbsp;Generating...'
    const url = 'https://api.elliotfriend.com/sq/sq06'
    let data = await fetch(url)
    let json = await data.json()
    console.log(json)
    await setClue(json.clue)
    clueButton.innerHTML = 'Generate Another Clue'
    clueButton.disabled = false
    document.getElementById('verifyButton').disabled = false
  }

  const verifyQuest = async () => {
    let verifyButton = document.getElementById('verifyButton')
    verifyButton.disabled = true
    verifyButton.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>'
    verifyButton.innerHTML += '&nbsp;Verifying...'
    const url = 'https://api.elliotfriend.com/sq/sq06'
    let data = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ public_key: clue.public })
    })
    let json = await data.json()
    console.log(json)
    await setVerified(json)
    verifyButton.innerHTML = 'Verify'
    verifyButton.disabled = false
  }

  return (
    <main className="container mt-5 text-start">
      <h2>Silly Side Quest 06</h2>
      <h2><small>Mux that</small></h2>
      <h3 className="mt-5">Goals and Details</h3>
      <div className="bg-dark">
        <p className="p-3 mb-0">
          Have you ever used Coinbase? I have. You probably have, too. You know what I hate about Coinbase? Sending XLM to my Coinbase account! Like, I mean it really sucks!
        </p>
        <p className="p-3 mb-0">
          You have to be <strong>absolutely positive</strong> that you include your memo string, or else the Lumens just won't show up! I nearly lost 500USD worth of XLM because I put the memo in the wrong place, when I sent the thing from Keybase. Then, you're never sure if your memo has changed since the last time you made a transfer, and you don't know if it's just one memo per customer, or any number of other confusing things! And, Coinbase isn't the only exchange to do that! It's actually pretty common (and it actually serves a purpose, too). But, it's such a pain!
        </p>
        <p className="p-3 mb-0">
          Enter, our new friend: <strong>Muxed accounts</strong>! These aren't <em>quite</em> a part of the ledger, but they fill the same purpose as the memo field does for exchanges. And they give you an individualized address you can use, and you won't ever be able to forget your memo! Hooray!
        </p>
        <p className="p-3 mb-0">
          When you generate a clue below, you will be given the public key to a sponsored, 0-balance account, along with a number of Muxed Account IDs. In order to successfully verify completion of the quest, you will need to make (at least) one payment to each of the muxed account IDs, in such a way that the total XLM payments you make to all the muxed account IDs must equal the sum of the muxed account IDs. Would an example help?
        </p>
        <p className="p-3 mb-0">
          Let's say you're muxed account IDs <code>[100, 5, 25]</code>. You'll need to submit at least 3 payment operations, at least one to each of the muxed account IDs, and the sum of all the payments made sould be 130XLM.
        </p>
        <p className="p-3 mb-0">
          The cool thing about these accounts is that even though you're sending multiple payments to multiple <em>different</em> "addresses," all the funds end up in a single bucket! Go ahead, check the balance on the <code>G...</code> address once you're done. Pretty neat!
        </p>
        <p className="p-3 mb-0">
          I've left out a lot of information about Muxed Accounts. If you need some more information, <a href="https://developers.stellar.org/docs/glossary/muxed-accounts/">here is the developers documentation for Muxed Accounts</a>. It's a great resource, and I highly recommend checking it out.
        </p>
        <p className="p-3 mb-0">
          Good luck. You know where to find me, if you need some help 😉
        </p>
        <p className="p-3 mb-0">
          <em>Please Note:</em> This is all taking place on the testnet for now. Please don't lose real XLM!
        </p>
      </div>
      <button className="mt-5 btn btn-primary" id="clueButton" onClick={fetchClue}>
        Generate Clue
      </button>
      <button className="mt-5 ms-3 btn btn-primary" id="verifyButton" onClick={verifyQuest}>
        Verify
      </button>
      {
        verified.message
        ? <div className={verified.success ? "mt-3 alert alert-success" : "mt-3 alert alert-danger"}>
            {verified.message}
          </div>
        : null
      }
      {
        typeof(clue) === 'object'
        ? <p className="mt-3 bg-dark p-3">
            <strong>Public Key:</strong> {clue.public}<br />
            <strong>Muxed Account IDs:</strong> {clue.muxes.join(', ')}
          </p>
        : <p className="mt-3 bg-dark p-3">
            {clue}
          </p>
      }
    </main>
  )
}

export default SQ06
