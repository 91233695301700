import { useState } from 'react'

const SQ04 = () => {
  let [ clue, setClue ] = useState('Clue will be displayed here.')
  let [ verified, setVerified ] = useState({success: false, message: ''})

  const fetchClue = async () => {
    let clueButton = document.getElementById('clueButton')
    clueButton.disabled = true
    clueButton.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>'
    clueButton.innerHTML += '&nbsp;Generating...'
    const url = 'https://api.elliotfriend.com/sq/sq04'
    let data = await fetch(url)
    let json = await data.json()
    console.log(json)
    await setClue(json.clue)
    clueButton.innerHTML = 'Generate Another Clue'
    clueButton.disabled = false
    document.getElementById('verifyButton').disabled = false
  }

  const verifyQuest = async () => {
    let verifyButton = document.getElementById('verifyButton')
    verifyButton.disabled = true
    verifyButton.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>'
    verifyButton.innerHTML += '&nbsp;Verifying...'
    const url = 'https://api.elliotfriend.com/sq/sq04'
    let data = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ public_key: clue.public })
    })
    let json = await data.json()
    console.log(json)
    await setVerified(json)
    verifyButton.innerHTML = 'Verify'
    verifyButton.disabled = false
  }

  return (
    <main className="container mt-5 text-start">
      <h2>Silly Side Quest 04</h2>
      <h2><small>Create an atomicity bomb</small></h2>
      <h3 className="mt-5">Goals and Details</h3>
      <div className="bg-dark">
        <p className="p-3 mb-0">
          There has been discussion in the SQ community, from time to time, about "atomic transactions." That just means a single transactions with a set of effects on one or more accounts crammed into multiple operations within the one transaction. The benefit of this strategy is that every one of the operations either succeeds or fails together.
        </p>
        <p className="p-3 mb-0">
          So, your task today is to create and submit one single transaction onto the (test) network that will accomplish <strong>all</strong> of the following objectives. Furthermore, in order to pass this quest, this transaction <strong>must contain at least 7 (seven) operations</strong>.
        </p>
        <p className="p-3 mb-0">
          Submit a single transaction that:
        </p>
        <ul className="py-3 mb-0">
          <li>Creates the quest account, with a starting balance of 500 XLM</li>
          <li>Adds two signers to the account (one <code>hash(x)</code> signer and one public key signer)</li>
          <li>Sets the account's <code>master_weight</code> to 0, and the other signer weights and thresholds so that either of the signatures can be used for <code>low</code> or <code>medium</code> threshold operations, but both signatures are required for any <code>high</code> threshold operations.</li>
          <li>Contains a memo of the <code>MEMO_TEXT</code> type</li>
          <li>Set's the quest account's inflation destination to the account that was used to create the quest account</li>
          <li>Creates a claimable balance for any account that does not already exist</li>
          <li>Creates that account, with a starting balance of 0 XLM</li>
        </ul>
        <p className="p-3 mb-0">
          Good luck. You know where to find us, if you need some help 😉
        </p>
        <p className="p-3 mb-0">
          <em>Please Note:</em> This is all taking place on the testnet for now. Please don't lose real XLM!
        </p>
      </div>
      <button className="mt-5 btn btn-primary" id="clueButton" onClick={fetchClue}>
        Generate Clue
      </button>
      <button className="mt-5 ms-3 btn btn-primary" id="verifyButton" onClick={verifyQuest}>
        Verify
      </button>
      {
        verified.message
        ? <div className={verified.success ? "mt-3 alert alert-success" : "mt-3 alert alert-danger"}>
            {verified.message}
          </div>
        : null
      }
      {
        typeof(clue) === 'object'
        ? <p className="mt-3 bg-dark p-3">
            <strong>Public Key:</strong> {clue.public}<br />
            <strong>Secret Key:</strong> {clue.secret}
          </p>
        : <p className="mt-3 bg-dark p-3">
            {clue}
          </p>
      }
    </main>
  )
}

export default SQ04
