import React from 'react';
import { render } from 'react-dom';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import './index.css';
import App from './components/App/App';
import Hero from './components/Hero/Hero'
import SQ01 from './routes/sq01'
import SQ02 from './routes/sq02'
import SQ03 from './routes/sq03'
import SQ04 from './routes/sq04'
import SQ05 from './routes/sq05'
import SQ06 from './routes/sq06'
import Footer from './components/Footer/Footer'
import reportWebVitals from './reportWebVitals';

render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} >
        <Route index element={<Hero />} />
        <Route path="sq01" element={<SQ01 />} />
        <Route path="sq02" element={<SQ02 />} />
        <Route path="sq03" element={<SQ03 />} />
        <Route path="sq04" element={<SQ04 />} />
        <Route path="sq05" element={<SQ05 />} />
        <Route path="sq06" element={<SQ06 />} />
      </Route>
    </Routes>
    <Footer />
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
