import { useState } from 'react'

const SQ01 = () => {
  let [ clue, setClue ] = useState('Clue will be displayed here.')

  const fetchClue = async () => {
    let clueButton = document.getElementById('clueButton')
    clueButton.disabled = true
    clueButton.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>'
    clueButton.innerHTML += '&nbsp;Generating...'
    const url = 'https://api.elliotfriend.com/sq/sq01'
    let data = await fetch(url)
    let json = await data.json()
    await setClue(json.clue)
    clueButton.innerHTML = 'Generate Another Clue'
    clueButton.disabled = false
  }

  return (
    <main className="container mt-5 text-start">
      <h2>Silly Side Quest 01</h2>
      <h2><small>Locate the <code>hash(x)</code> signer</small></h2>
      <h3 className="mt-5">Goals and Details</h3>
      <p className="bg-dark p-3">
        An account has been setup with an extra signer, of the <code>hash(x)</code> kind. Your task is to find the relevant preimage, and use it to claim the lumens at stake. There is <strong>250 XLM</strong> up for grabs!
        <br />
        <br />
        Go get 'em, champ!
        <br />
        <br />
        <em>Please Note:</em> This is all taking place on the testnet for now. Please don't lose real XLM!
      </p>
      <button className="mt-5 btn btn-primary" id="clueButton" onClick={fetchClue}>
        Generate Clue
      </button>
      <p className="mt-3 bg-dark p-3">{clue}</p>
    </main>
  )
}

export default SQ01
