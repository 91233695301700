import { useState } from 'react'

const SQ05 = () => {
  let [ clue, setClue ] = useState('Clue will be displayed here.')
  let [ verified, setVerified ] = useState({success: false, message: ''})

  const fetchClue = async () => {
    let clueButton = document.getElementById('clueButton')
    clueButton.disabled = true
    clueButton.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>'
    clueButton.innerHTML += '&nbsp;Generating...'
    const url = 'https://api.elliotfriend.com/sq/sq05'
    let data = await fetch(url)
    let json = await data.json()
    console.log(json)
    await setClue(json.clue)
    clueButton.innerHTML = 'Generate Another Clue'
    clueButton.disabled = false
    document.getElementById('verifyButton').disabled = false
  }

  const verifyQuest = async () => {
    let verifyButton = document.getElementById('verifyButton')
    verifyButton.disabled = true
    verifyButton.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>'
    verifyButton.innerHTML += '&nbsp;Verifying...'
    const url = 'https://api.elliotfriend.com/sq/sq05'
    let data = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ public_key: clue.public })
    })
    let json = await data.json()
    console.log(json)
    await setVerified(json)
    verifyButton.innerHTML = 'Verify'
    verifyButton.disabled = false
  }

  return (
    <main className="container mt-5 text-start">
      <h2>Silly Side Quest 05</h2>
      <h2><small>Bump, bump, bump it up</small></h2>
      <h3 className="mt-5">Goals and Details</h3>
      <div className="bg-dark">
        <p className="p-3 mb-0">
          Sequence numbers are pretty critical to the Stellar network. They can be used to create a series of transactions that must be submitted in a particular order. They can be used to invalidate previously prepared transactions, as well. They pretty much control everything, when you think about it...
        </p>
        <p className="p-3 mb-0">
          Today, your job is "simple." You can generate a <em>funded</em> Quest Account below. All you have to do is increase the sequence number of your quest account to the <code>target_sequence</code>. That's it. I (hopefully) haven't thrown too many weighty stumbling blocks in your path.
        </p>
        <p className="p-3 mb-0">
          Good luck. You know where to find me, if you need some help 😉
        </p>
        <p className="p-3 mb-0">
          <em>Please Note:</em> This is all taking place on the testnet for now. Please don't lose real XLM!
        </p>
      </div>
      <button className="mt-5 btn btn-primary" id="clueButton" onClick={fetchClue}>
        Generate Clue
      </button>
      <button className="mt-5 ms-3 btn btn-primary" id="verifyButton" onClick={verifyQuest}>
        Verify
      </button>
      {
        verified.message
        ? <div className={verified.success ? "mt-3 alert alert-success" : "mt-3 alert alert-danger"}>
            {verified.message}
          </div>
        : null
      }
      {
        typeof(clue) === 'object'
        ? <p className="mt-3 bg-dark p-3">
            <strong>public_key:</strong> {clue.public}<br />
            <strong>target_sequence:</strong> {clue.target_sequence}
          </p>
        : <p className="mt-3 bg-dark p-3">
            {clue}
          </p>
      }
    </main>
  )
}

export default SQ05
