import './Header.css'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <header className="p-3 bg-dark">
      <div className="container">
        <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-start">
          <a href="/" className="me-md-auto navbar-brand text-reset">
            <h5 className="my-auto">
              <img src="/assets/logo.svg" alt="Stellar Quest Logo" />
              <span>Silly Side Quests</span>
            </h5>
          </a>
          <ul className="nav d-grid gap-2 d-md-flex justify-content-md-end">
            <li className="nav-item dropdown">
              <button className="nav-link dropdown-toggle btn btn-primary text-white" data-bs-toggle="dropdown" aria-expanded="false">Quests</button>
              <ul className="dropdown-menu">
                <li className="nav-item"><Link className="dropdown-item" to="/sq01">SQ01</Link></li>
                <li className="nav-item"><Link className="dropdown-item" to="/sq02">SQ02</Link></li>
                <li className="nav-item"><Link className="dropdown-item" to="/sq03">SQ03</Link></li>
                <li className="nav-item"><Link className="dropdown-item" to="/sq04">SQ04</Link></li>
                <li className="nav-item"><Link className="dropdown-item" to="/sq05">SQ05</Link></li>
                <li className="nav-item"><Link className="dropdown-item" to="/sq06">SQ06</Link></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </header>
  )
}

export default Header
